import React from 'react';
import PropTypes from 'prop-types';
import { Link, Typography, Grid, List, ListItem, ListItemText, ListItemIcon } from '@material-ui/core';
import { Check as CheckIcon, Phone as PhoneIcon, MailOutline as EmailIcon, HighlightOff as CloseIcon, Room as AddressIcon, ContactSupport as HelpIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import MasterLayout from '../layouts/MasterLayout';


const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 1200,
  },
  logo: {
    maxHeight: 150,
    maxWidth: '100%',
  },
  checks: {
    display: 'flex',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    '& > *': {
      width: '50%',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
  },
  link: {
    color: 'hsla(195, 100%, 50%)',
  },
  check: {
    color: 'green',
  },
}));

const HomePage = () => {
  const classes = useStyles();

  return (
    <MasterLayout>
      <Grid container spacing={4} justify="center" alignItems="center" direction="column" className={classes.root}>
        <Grid item>
          <img className={classes.logo} alt="Slick Systems logo" src="/images/logo-portrait-blue-orange.png" />
        </Grid>

        <Grid item>
          <Typography variant="h4" align="center">
            Business Management Solutions
          </Typography>
          <Typography variant="body1" color="textSecondary" align="center">
            Effortless, smooth, and efficient operations for your business<br />
            {/* <Link href="https://demo.slick.systems" className={classes.link}>demo</Link>. */}
          </Typography>

          <br /><br />

          <Typography variant="h5" align="center" color="secondary">
            Customized solutions for:
          </Typography>
          <List className={classes.checks}>
            <ListItem href="tel:604-414-4962">
              <ListItemIcon><CheckIcon className={classes.check} /></ListItemIcon>
              <ListItemText
                primary="Trades"
                secondary="Welding & fabrication shops, electrical & plumbing contracting, hazardous materials removal, etc."
              />
            </ListItem>
            <ListItem href="tel:604-414-4962">
              <ListItemIcon><CheckIcon className={classes.check} /></ListItemIcon>
              <ListItemText
                primary="Construction"
                secondary="Project costing, time tracking, subs management, etc."
              />
            </ListItem>
            <ListItem href="tel:604-414-4962">
              <ListItemIcon><CheckIcon className={classes.check} /></ListItemIcon>
              <ListItemText
                primary="Forestry & Land Management"
                secondary="GIS integration, harvest assessments, digital forms, block management, etc."
              />
            </ListItem>
            <ListItem href="tel:604-414-4962">
              <ListItemIcon><CheckIcon className={classes.check} /></ListItemIcon>
              <ListItemText
                primary="Business Management"
                secondary="Document & photo mangement, workflow automation and digitization, customer management, invoicing and accounting integration, and more..."
              />
            </ListItem>
          </List>
        </Grid>

        <Grid item>
          <Typography variant="h5" align="center" color="secondary">
            Call today for a demo
          </Typography>
          <List>
            <ListItem button component="a" href="tel:604-414-4962">
              <ListItemIcon><PhoneIcon /></ListItemIcon>
              <ListItemText
                primary="Sales - Jordan Hamilton"
                primaryTypographyProps={{ className: classes.link }}
                secondary={(
                  <>
                    1-604-414-4962<br />
                    8am - 5:30pm, Monday - Friday
                  </>
                )}
              />
            </ListItem>
            <ListItem button component="a" href="tel:604-618-9598">
              <ListItemIcon><PhoneIcon /></ListItemIcon>
              <ListItemText
                primary="Technical Support - Noland Germain"
                primaryTypographyProps={{ className: classes.link }}
                secondary={(
                  <>
                    1-604-618-9598<br />
                    8am - 5:30pm, Monday - Friday
                  </>
                )}
              />
            </ListItem>
            <ListItem button component="a" href="mailto:noland@slick.systems">
              <ListItemIcon><EmailIcon /></ListItemIcon>
              <ListItemText
                primary="info@slick.systems"
                primaryTypographyProps={{ className: classes.link }}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon><AddressIcon /></ListItemIcon>
              <ListItemText
                primary="7-3965 Yaroshuk Road"
                primaryTypographyProps={{}}
                secondary={(
                  <>
                    Powell River, BC, Canada<br />
                    V8A 0P8
                  </>
                )}
              />
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </MasterLayout>
  );
};

HomePage.propTypes = {
};
HomePage.defaultProps = {
};

export default HomePage;
