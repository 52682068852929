import React from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Typography } from '@material-ui/core';
import { ThemeProvider, makeStyles } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';

import { themes } from 'src/style/themes';

const siteTheme = themes.dark;

const useStyles = makeStyles((theme) => ({
  '@global': {
    body: {
      background: 'hsla(0, 0%, 10%, 1)',
    },
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '100vh',
    position: 'relative',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    background: 'url(/images/diamond-plate.jpg)',
    opacity: 0.1,
  },
  header: {
    zIndex: 10,
  },
  main: {
    width: '100%',
    padding: theme.spacing(4),
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 10,
  },
  footer: {
    zIndex: 10,
    padding: theme.spacing(2),
    opacity: 0.5,
  },
}));

const MasterLayout = (props) => {
  const { children } = props;
  const classes = useStyles(props);

  return (
    <ThemeProvider theme={siteTheme}>
      <Helmet>
        <title>Slick Systems - Business Management Solutions</title>
      </Helmet>
      <CssBaseline />
      <div className={classes.root}>
        <div className={classes.overlay} />
        <header className={classes.header} />
        <main className={classes.main}>
          {children}
        </main>
        <footer className={classes.footer}>
          <Typography variant="caption">&copy; Copyright 2020 - Slick Systems Business Managment Solutions</Typography>
        </footer>
      </div>
    </ThemeProvider>
  );
};

MasterLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

MasterLayout.defaultProps = {
};

export default MasterLayout;
